<template>
  <div>
    <NavigationBar titel="Abrechnung"></NavigationBar>

    <section v-if="!loading && noBillingAccountConnected">
      <v-container class="fill-height mt-16">
        <v-row align="top" justify="center">
          <v-col cols="12" sm="12" md="8">
            <v-card>
              <v-card-title class="d-flex justify-center"
                ><div class="text-center">
                  <v-avatar color="primary" rounded size="70" class="mb-4">
                    <v-icon x-large color="white">
                      mdi-credit-card-off-outline</v-icon
                    >
                  </v-avatar>
                  <div>Kein Abrechnungskonto verknüpft</div>
                </div>
              </v-card-title>
              <v-card-actions
                ><v-btn
                  @click="useExistingBillingAccount()"
                  depressed
                  class="mb-2 ml-2"
                  ><v-icon left>mdi-link-variant</v-icon>Bestehendes
                  Abrechnungskonto verwenden</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  @click="createNewBillingAccount()"
                  depressed
                  color="primary"
                  class="mb-2 mr-2"
                  ><v-icon left>mdi-credit-card-plus-outline</v-icon>Neues
                  Abrechnungskonto erstellen</v-btn
                ></v-card-actions
              >
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section v-else>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12" md="3"
            ><v-card>
              <v-card-title
                >Juli 2022
                <v-spacer></v-spacer>
                <v-btn depressed small
                  ><v-icon left>mdi-history</v-icon>Historie</v-btn
                >
              </v-card-title>
              <v-card-subtitle
                >Aktueller Abrechnungszeitraum</v-card-subtitle
              ></v-card
            ></v-col
          >
          <v-col cols="12" md="3"
            ><v-card>
              <v-card-title
                >Monatlich
                <v-spacer></v-spacer>
                <v-btn depressed small
                  ><v-icon left>mdi-swap-horizontal</v-icon>Wechseln</v-btn
                >
              </v-card-title>
              <v-card-subtitle
                >Aktueller Abrechnungszyklus</v-card-subtitle
              ></v-card
            ></v-col
          >
          <v-col cols="12" md="3">
            <v-card>
              <v-card-title
                >Premium
                <v-spacer></v-spacer>
                <v-btn depressed small
                  ><v-icon left>mdi-pencil-circle</v-icon>Bearbeiten</v-btn
                >
              </v-card-title>
              <v-card-subtitle>Aktiver Plan</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" md="3"
            ><v-card>
              <v-card-title>
                1262419
                <v-spacer></v-spacer>
                <v-btn depressed small
                  ><v-icon left>mdi-content-copy</v-icon>Kopieren</v-btn
                >
              </v-card-title>
              <v-card-subtitle>Kundennummer</v-card-subtitle></v-card
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12" md="8"
            ><v-card
              ><v-card-subtitle>Kostenvorschau</v-card-subtitle></v-card
            ></v-col
          >
          <v-col cols="12" md="4"
            ><v-card
              ><v-card-subtitle>Aktueller Plan</v-card-subtitle>
              <v-card-text>
                <v-card outlined
                  ><v-list
                    ><v-list-item
                      ><v-list-item-title>Test</v-list-item-title></v-list-item
                    ></v-list
                  ></v-card
                >
              </v-card-text></v-card
            ></v-col
          >
        </v-row>
      </v-container>
    </section>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "admin-billing",
  components: {
    NavigationBar,
  },
  computed: {
    loading() {
      return false;
    },
    noBillingAccountConnected() {
      return true;
      // return this.$store.state.organization.activeOrganization.config
      //   .inMaintenance;
    },
  },
  methods: {
    useExistingBillingAccount() {
      alert(
        "Um ein bestehendes Abrechnungskonto zu verwenden, müssen Sie sich an den Support wenden.\n\nGeben Sie dabei folgende Daten an:\n\nIhre existierende Kundenummer\nZu verknüpfende Organisations-ID: " +
          this.$route.params.organizationId
      );
    },
    createNewBillingAccount() {
      alert(
        "Um ein neues Abrechnungskonto zu erstellen, müssen Sie sich an den Support wenden.\n\nGeben Sie dabei folgende Daten an:\n\nZu verknüpfende Organisations-ID: " +
          this.$route.params.organizationId
      );
    },
  },
};
</script>
